<template>
  <div class="editBtn">
    <ColorTextBtn size="small" @click="changePage">编辑</ColorTextBtn>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    changePage() {
      this.$router.push({
        path: '/infoManage/message/templateChange',
        query: {
          type: 'edit',
          id: this.row.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.editBtn {
  display: inline-block;
}
</style>
